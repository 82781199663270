<template>
	<div class="hello">
		<h1>Conte Fácil</h1>
		<label>Data Inicial: </label>
		<input type="date" v-model="startDate">
		<label>Data Final: </label>
		<input type="date" v-model="endDate" v-on:change="daysCount">
		<div v-if="countersData != null ">
			<br>
			Data Inicial: {{countersData.sDate}} <br>
			Data Final: {{countersData.eDate}} <br>
			Núm. Dias: {{countersData.days}} <br>
			Núm. Semanas: {{countersData.weeks}} <br>
			Núm. Feriados: {{countersData.holidays}} <br>
			Feriados (Sáb/Dom): {{countersData.holidaysNoWork}} <br>
			Num. Dias Úteis: {{countersData.workDaysTotal}} <br>
		</div>		
	</div>
</template>

<script>
	import axios from 'axios';
	export default {

		data () {
		return {
			startDate: "",
			endDate: "",
			countersData: null,
			keysData : []
		}
		},
		
		methods:{	  
			daysCount(){
				var self = this;			
				axios.get('https://001.sunsys.com.br/public/api/days-count/'+this.startDate+'/'+this.endDate)
				
				.then(function (response) {
					console.log(response);
					self.countersData = response.data;
					self.keysData = Object.keys( self.countersData  );
				});
			}
		}
	}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.hello {
  color: #333;
  padding: 20px;
  max-width: 1200px;
  margin: auto;
}

.card {
  background-color: #fff;
  color: #333;
}

.post {
  border: 1px solid silver;
  padding: 10px;
  margin-top: 10px;
  text-align: left;
}

h3 {
  border-bottom: 1px dotted silver;
  margin-bottom: 10px;
}

</style>