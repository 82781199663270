<template>
  <div class="home">
	<Navbar />
    <DaysCounter msg="Conte Fácil"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'
import DaysCounter from '@/components/DaysCounter.vue'

export default {
	name: 'Home',
	components: {
		Navbar,
		DaysCounter
	}
}
</script>
